// import React, { memo } from 'react';
// import { FaFilePdf } from 'react-icons/fa';

// const BookPrivateDownload = () => {
//   const handleDownload = (url) => {
//     // Create a temporary link element
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', '');

//     // Append to the document
//     document.body.appendChild(link);

//     // Trigger the download
//     link.click();

//     // Remove the link element
//     document.body.removeChild(link);
//   };

//   return (
//     <div id="download" className="download" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//       <div className="container">
//         <div className="row justify-content-center align-items-center">
//           <div className="col-lg-8 col-sm-12 text-center">
//             <div className="title-box">
//               <div className="title wow fadeInUp" style={{ color: 'white' }} data-wow-duration="1.0s">
//                 Book a Private Session Today!
//               </div>
//               <div className="sub-title wow fadeInUp" data-wow-duration="2.0s">
//                 Get the information you need to book a private session or become a coach with our easy-to-download guides.
//               </div>
//             </div>
//             <div className="wow fadeInUp mt-4" data-wow-duration="1.0s">
//               <button
//                 className="custom-button mr-4 mt-2"
//                 onClick={() => handleDownload('https://digiyo-assets.s3.amazonaws.com/Walkthroug+hPDF.pdf')}
//               >
//                 <FaFilePdf style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
//                 How to Book a Private
//               </button>
//               <button
//                 className="custom-button mt-2"
//                 onClick={() => handleDownload('https://digiyo-assets.s3.amazonaws.com/Walkthroug+hPDF.pdf')}
//               >
//                 <FaFilePdf style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
//                 How to Become a Coach
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default memo(BookPrivateDownload);

import React, { memo } from 'react';
import download from '../assets/images/download/bookaprivate.svg';
import background from '../assets/images/download/bookaprivatebanner.svg';
import { FaFilePdf } from 'react-icons/fa';

const BookPrivateDownload = () => {
  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="download" className="download" style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#000',
        color: 'white',
        // padding: '50px 0',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-sm-12 text-center">
            <img className="img-fluid wow fadeInLeft" data-wow-duration="2.0s" src={download} alt="Download" />
          </div>
          <div className="col-lg-6 col-sm-12 text-center">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 col-sm-12">
                <div className="title-box">
                  <div className="title wow fadeInUp" style={{ color: 'white' }} data-wow-duration="1.0s">
                    Book a Private Session Today!
                  </div>
                  <div className="sub-title wow fadeInUp" data-wow-duration="2.0s">
                    Get the information you need to book a private session or become a coach with our easy-to-download guides.
                  </div>
                </div>
                <div className="wow fadeInUp mt-4" data-wow-duration="1.0s">
                  <button
                    className="custom-button mr-4 mt-2"
                    onClick={() => handleDownload('https://digiyo-assets.s3.amazonaws.com/Walkthroug+hPDF.pdf')}
                  >
                    <FaFilePdf style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
                    How to Book a Private
                  </button>
                  <button
                    className="custom-button mt-2"
                    onClick={() => handleDownload('https://digiyo-assets.s3.amazonaws.com/Walkthroug+hPDF.pdf')}
                  >
                    <FaFilePdf style={{ marginRight: '10px', color: 'red', fontSize: '20px' }} />
                    How to Become a Coach
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BookPrivateDownload);
