import React, { memo } from 'react';
import main_img from '../assets/images/about-us/main.png';
import banner from '../assets/images/banner/1.png';
import { Link } from 'react-router-dom';
// import TypeWriterEffect from 'react-typewriter-effect';
import { Typewriter } from 'react-simple-typewriter'

const Banner = () => {

    return (
        <div id="banner" className="banner">
            <img className="banner-img" src={banner} alt="." />
            <div className="circle"></div>
            <div className="square"></div>
            <div className="triangle"></div>
            <div className="plus">+</div>
            <div className="icon-bg"></div>
            <div className="icon-bg1"></div>
            <div className="banner-text">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                        <h1 className="effect-headline clip is-full-width tb-tw-7 tb-font-white">
                                <span>Monetize Your Sporting Content</span><br />
                                <span className="effect-words-wrapper is-full-width" style={{fontWeight: 900}}>
                                    {/* <TypeWriterEffect
                                        textStyle={{
                                            fontFamily: 'FontAwesome',
                                            color: '#000000',
                                            fontWeight: 800,
                                        }}
                                        startDelay={2000}
                                        cursorColor="#3F3D56"
                                        multiText={[
                                            'Join digiYO Community', 
                                            'Sporting Stadium', 
                                            'Online Social Circle', 
                                            'Ultimate Sporting Experience'
                                        ]}
                                        multiTextDelay={1000}
                                        typeSpeed={50}
                                    /> */}
                                    <Typewriter
                                        words={[
                                            'Join digiYO Community', 
                                            'Challenge Match With Peeps', 
                                            'Book A Private With Coaches', 
                                            'Capture Sporting Experience Highlights'
                                        ]}
                                        loop={5}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </span>
                            </h1>
                            <p className="mt-3">
                                digiYO offers a platform for sports enthusiasts and executives to connect, share,
                                and monetize their content. Join us to experience the ultimate sporting community.
                            </p>
                            <Link to="/download" className="button mt-2 mr-2">Download</Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                            <img style={{width: '36rem'}} className="banner-phone wow fadeInUp" data-wow-duration="1.0s" src={main_img} alt="." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Banner);



