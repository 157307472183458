// import React, { memo, useEffect, useRef, useState } from 'react';
// import appStoreImg from '../assets/images/appstore.jpeg';
// import playStoreImg from '../assets/images/playstore.jpeg';
// import backgroundImage from '../assets/images/Banner.png'; // Import the background image
// import { FaPlay, FaPause } from 'react-icons/fa';
// import { Link } from 'react-router-dom';

// const Raw = () => {
//     const videoRef = useRef(null);
//     const [isPlaying, setIsPlaying] = useState(true);
//     const [showButton, setShowButton] = useState(true);
//     const timeoutRef = useRef(null);

//     const videoSrc = 'https://digiyo-assets.s3.amazonaws.com/VIDEO-2024-08-08-08-18-09.mp4';

//     useEffect(() => {
//         if (videoRef.current) {
//             if (!isPlaying) {
//               videoRef.current.play();
//             } 
//         }
//     }, []);

//     const handlePlayPause = () => {
//         if (videoRef.current) {
//           if (isPlaying) {
//             videoRef.current.pause();
//           } else {
//             videoRef.current.play().catch(error => {
//                 console.error("Playback failed:", error);
//                 // In case of playback failure, show play button to let the user start the video
//                 setShowButton(true);
//               });
//           }
//           setIsPlaying(!isPlaying);
    
//           // Show the button and reset timeout when clicked
//           setShowButton(true);
    
//           // Clear the previous timeout if it exists
//           if (timeoutRef.current) {
//             clearTimeout(timeoutRef.current);
//           }
    
//           // Hide the button after 5 seconds
//           timeoutRef.current = setTimeout(() => {
//             setShowButton(false);
//           }, 5000);
//         }
//       };
    
//       const handleContainerClick = () => {
//         handlePlayPause(); // Reuse the play/pause logic
//       };
    
//       const handleVideoClick = (e) => {
//         e.stopPropagation(); // Prevent video click from triggering the container click
//         handlePlayPause();
//       };
    
//       useEffect(() => {
//         // Automatically hide the button 5 seconds after the component mounts
//         timeoutRef.current = setTimeout(() => {
//           setShowButton(false);
//         }, 5000);
    
//         return () => {
//           if (timeoutRef.current) {
//             clearTimeout(timeoutRef.current);
//           }
//         }; // Cleanup timeout on component unmount
//       }, []);
    
//       useEffect(() => {
//         // Reset the button visibility timer when the video is clicked
//         if (showButton) {
//           if (timeoutRef.current) {
//             clearTimeout(timeoutRef.current);
//           }
//           timeoutRef.current = setTimeout(() => {
//             setShowButton(false);
//           }, 5000);
//         }
//       }, [showButton]);

//   return (
//     <section 
//       data-testid="digiYO_section" 
//       className="digiYOSection"
//       style={{ 
//         backgroundImage: `url(${backgroundImage})`, 
//         backgroundRepeat: 'no-repeat', 
//         backgroundPosition: '0% 0%', 
//         // backgroundSize: '72%'
//       }}
//     >
//       <div className="digiYOContainer">
//         <div className="digiYODownloadContainer">
//             <a href="https://apps.apple.com/app/digiyo/id6502759122" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                 <img src={appStoreImg} alt="Download on the App Store" />
//             </a>
//             <a href="https://play.google.com/store/apps/details?id=com.digiYO" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                 <img src={playStoreImg} alt="Get it on Google Play" />
//             </a>
//         </div>
//         <div className="videoContainer" onClick={handleContainerClick}>
//             <video
//                 ref={videoRef}
//                 autoPlay
//                 muted={false}
//                 loop
//                 playsInline
//                 className="videoElement"
//                 onClick={handleVideoClick} // Handle video click separately
//             >
//                 <source
//                 src={videoSrc}
//                 type="video/mp4"
//                 />
//             </video>
//             {showButton && (
//                 <button
//                 className="playPauseButton"
//                 onClick={(e) => {
//                     e.stopPropagation(); // Prevent button click from triggering the container click
//                     handlePlayPause();
//                 }}
//                 >
//                 {isPlaying ? <FaPause color='#1877F2' size={'50px'} /> : <FaPlay color='#1877F2' size={'50px'}/>}
//                 </button>
//             )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default memo(Raw);
// import React, { memo, useEffect, useRef, useState } from 'react';
// import appStoreImg from '../assets/images/appstore.jpeg';
// import playStoreImg from '../assets/images/playstore.jpeg';
// import backgroundImage from '../assets/images/Banner.png'; // Import the background image
// import backgroundImageMobile from '../assets/images/Banner(1).png'; // Import the background image
// import { FaPlay, FaPause } from 'react-icons/fa';

// const Raw = () => {
//     const videoRef = useRef(null);
//     const [isPlaying, setIsPlaying] = useState(false); // Default to false
//     const [showButton, setShowButton] = useState(true);
//     const timeoutRef = useRef(null);

//     const videoSrc = 'https://digiyo-assets.s3.amazonaws.com/VIDEO-2024-08-08-08-18-09.mp4';

//     useEffect(() => {
//         const playVideo = async () => {
//             if (videoRef.current) {
//                 try {
//                     await videoRef.current.play();
//                     setIsPlaying(true);
//                 } catch (error) {
//                     console.error("Playback failed:", error);
//                     setShowButton(true);
//                 }
//             }
//         };

//         playVideo();

//         // Attempt to play the video when the component mounts
//         return () => {
//             if (videoRef.current) {
//                 videoRef.current.pause(); // Clean up by pausing
//             }
//         };
//     }, []);

//     const handlePlayPause = () => {
//         if (videoRef.current) {
//             if (isPlaying) {
//                 videoRef.current.pause();
//             } else {
//                 videoRef.current.play().then(() => {
//                     videoRef.current.muted = false; // Unmute the video
//                 }).catch(error => {
//                     console.error("Playback failed:", error);
//                     setShowButton(true);
//                 });
//             }
//             setIsPlaying(!isPlaying);
    
//             setShowButton(true);
    
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
    
//             // Hide the button after 5 seconds
//             timeoutRef.current = setTimeout(() => {
//                 setShowButton(false);
//             }, 5000);
//         }
//     };
    
//     const handleContainerClick = () => {
//         handlePlayPause(); // Reuse the play/pause logic
//     };
    
//     const handleVideoClick = (e) => {
//         e.stopPropagation();
//         handlePlayPause();
//     };
    
//     useEffect(() => {
//         // Automatically hide the button 5 seconds after the component mounts
//         timeoutRef.current = setTimeout(() => {
//             setShowButton(false);
//         }, 5000);
    
//         return () => {
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
//         }; // Cleanup timeout on component unmount
//     }, []);
    
//     useEffect(() => {
//         // Reset the button visibility timer when the video is clicked
//         if (showButton) {
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
//             timeoutRef.current = setTimeout(() => {
//                 setShowButton(false);
//             }, 5000);
//         }
//     }, [showButton]);

//     return (
//         <section 
//             data-testid="digiYO_section" 
//             className="digiYOSection"
//             style={{ 
//                 backgroundImage: `url(${backgroundImage})`, 
//                 backgroundRepeat: 'no-repeat', 
//                 backgroundPosition: '0% 0%', 
//                 // backgroundSize: '72%'
//             }}
//         >
//             <div className="digiYOContainer">
//                 <div className="digiYODownloadContainer">
//                     <a href="https://apps.apple.com/app/digiyo/id6502759122" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                         <img src={appStoreImg} alt="Download on the App Store" />
//                     </a>
//                     <a href="https://play.google.com/store/apps/details?id=com.digiYO" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                         <img src={playStoreImg} alt="Get it on Google Play" />
//                     </a>
//                 </div>
//                 <div className="videoContainer" onClick={handleContainerClick}>
//                     <video
//                         ref={videoRef}
//                         muted
//                         loop
//                         playsInline
//                         className="videoElement"
//                         onClick={handleVideoClick} // Handle video click separately
//                     >
//                         <source
//                         src={videoSrc}
//                         type="video/mp4"
//                         />
//                     </video>
//                     {showButton && (
//                         <button
//                         className="playPauseButton"
//                         onClick={(e) => {
//                             e.stopPropagation();
//                             handlePlayPause();
//                         }}
//                         >
//                         {isPlaying ? <FaPause color='#1877F2' size={'50px'} /> : <FaPlay color='#1877F2' size={'50px'}/>}
//                         </button>
//                     )}
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default memo(Raw);

// import React, { memo, useEffect, useRef, useState } from 'react';
// import appStoreImg from '../assets/images/appstore.jpeg';
// import playStoreImg from '../assets/images/playstore.jpeg';
// import backgroundImage from '../assets/images/Banner.png'; // Import the background image
// import backgroundImageMobile from '../assets/images/MobileBanner.png'; // Import the mobile background image
// import { FaPlay, FaPause } from 'react-icons/fa';

// const Raw = () => {
//     const videoRef = useRef(null);
//     const [isPlaying, setIsPlaying] = useState(false); // Default to false
//     const [showButton, setShowButton] = useState(true);
//     const timeoutRef = useRef(null);
//     const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//     const videoSrc = 'https://digiyo-assets.s3.amazonaws.com/VIDEO-2024-08-08-08-18-09.mp4';

//     useEffect(() => {
//         const playVideo = async () => {
//             if (videoRef.current) {
//                 try {
//                     await videoRef.current.play();
//                     setIsPlaying(true);
//                 } catch (error) {
//                     console.error("Playback failed:", error);
//                     setShowButton(true);
//                 }
//             }
//         };

//         playVideo();

//         return () => {
//             if (videoRef.current) {
//                 videoRef.current.pause(); // Clean up by pausing
//             }
//         };
//     }, []);

//     const handlePlayPause = () => {
//         if (videoRef.current) {
//             if (isPlaying) {
//                 videoRef.current.pause();
//             } else {
//                 videoRef.current.play().then(() => {
//                     videoRef.current.muted = false; // Unmute the video
//                 }).catch(error => {
//                     console.error("Playback failed:", error);
//                     setShowButton(true);
//                 });
//             }
//             setIsPlaying(!isPlaying);
    
//             setShowButton(true);
    
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
    
//             // Hide the button after 5 seconds
//             timeoutRef.current = setTimeout(() => {
//                 setShowButton(false);
//             }, 5000);
//         }
//     };
    
//     const handleContainerClick = () => {
//         handlePlayPause(); // Reuse the play/pause logic
//     };
    
//     const handleVideoClick = (e) => {
//         e.stopPropagation();
//         handlePlayPause();
//     };
    
//     useEffect(() => {
//         // Automatically hide the button 5 seconds after the component mounts
//         timeoutRef.current = setTimeout(() => {
//             setShowButton(false);
//         }, 5000);
    
//         return () => {
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
//         }; // Cleanup timeout on component unmount
//     }, []);
    
//     useEffect(() => {
//         // Reset the button visibility timer when the video is clicked
//         if (showButton) {
//             if (timeoutRef.current) {
//                 clearTimeout(timeoutRef.current);
//             }
//             timeoutRef.current = setTimeout(() => {
//                 setShowButton(false);
//             }, 5000);
//         }
//     }, [showButton]);

//     // Handle window resize event to change background image based on screen width
//     useEffect(() => {
//         const handleResize = () => {
//             setWindowWidth(window.innerWidth);
//         };

//         window.addEventListener('resize', handleResize);

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const backgroundImageToUse = windowWidth <= 950 ? backgroundImageMobile : backgroundImage;

//     return (
//         <section 
//             data-testid="digiYO_section" 
//             className="digiYOSection"
//             style={{ 
//                 backgroundImage: `url(${backgroundImageToUse})`, 
//                 backgroundRepeat: 'no-repeat', 
//                 backgroundPosition: '0% 0%', 
//                 backgroundSize: '100%'
//             }}
//         >
//             <div className="digiYOContainer">
//                 <div className="digiYODownloadContainer">
//                     <a href="https://apps.apple.com/app/digiyo/id6502759122" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                         <img src={appStoreImg} alt="Download on the App Store" />
//                     </a>
//                     <a href="https://play.google.com/store/apps/details?id=com.digiYO" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
//                         <img src={playStoreImg} alt="Get it on Google Play" />
//                     </a>
//                 </div>
//                 <div className="videoContainer" onClick={handleContainerClick}>
//                     <video
//                         ref={videoRef}
//                         muted
//                         loop
//                         playsInline
//                         className="videoElement"
//                         onClick={handleVideoClick}
//                     >
//                         <source
//                             src={videoSrc}
//                             type="video/mp4"
//                         />
//                     </video>
//                     {showButton && (
//                         <button
//                         className="playPauseButton"
//                         onClick={(e) => {
//                             e.stopPropagation();
//                             handlePlayPause();
//                         }}
//                         >
//                         {isPlaying ? <FaPause color='#1877F2' size={'50px'} /> : <FaPlay color='#1877F2' size={'50px'}/>}
//                         </button>
//                     )}
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default memo(Raw);
import React, { memo, useEffect, useRef, useState } from 'react';
import appStoreImg from '../assets/images/appstore.jpeg';
import playStoreImg from '../assets/images/playstore.jpeg';
import { FaPlay, FaPause } from 'react-icons/fa';
import MobileImage from '../assets/images/MobileBanner.svg';
import './raw.css'

const Raw = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const timeoutRef = useRef(null);

    const videoSrc = 'https://digiyo-assets.s3.amazonaws.com/VIDEO-2024-08-08-08-18-09.mp4';


    useEffect(() => {
        const playVideo = async () => {
            if (videoRef.current) {
                try {
                    await videoRef.current.play();
                    setIsPlaying(true);
                } catch (error) {
                    console.error("Playback failed:", error);
                    setShowButton(true);
                }
            }
        };

        playVideo();

        return () => {
            if (videoRef.current) {
                videoRef.current.pause();
            }
        };
    }, []);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().then(() => {
                    videoRef.current.muted = false;
                }).catch(error => {
                    console.error("Playback failed:", error);
                    setShowButton(true);
                });
            }
            setIsPlaying(!isPlaying);
    
            setShowButton(true);
    
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
    
            timeoutRef.current = setTimeout(() => {
                setShowButton(false);
            }, 5000);
        }
    };
    
    const handleContainerClick = () => {
        handlePlayPause();
    };
    
    const handleVideoClick = (e) => {
        e.stopPropagation();
        handlePlayPause();
    };

    return (
        <div 
            data-testid="digiYO_section" 
            className="digiYOSection"
        >
            <div className="digiYOContainer">
                <div className='mobile-image'>
                    <img src={MobileImage} alt='digiYO-mobile-asset' />
                </div>
                <div className="digiYODownloadContainer">
                    <a href="https://apps.apple.com/app/digiyo/id6502759122" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
                        <img src={appStoreImg} alt="Download on the App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.digiYO" target="_blank" rel="noopener noreferrer" className="digiYODownloadButton">
                        <img src={playStoreImg} alt="Get it on Google Play" />
                    </a>
                </div>
                <div className="videoContainer" onClick={handleContainerClick}>
                    <video
                        ref={videoRef}
                        muted
                        loop
                        playsInline
                        className="videoElement"
                        onClick={handleVideoClick}
                    >
                        <source
                            src={videoSrc}
                            type="video/mp4"
                        />
                    </video>
                    {showButton && (
                        <button
                        className="playPauseButton"
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePlayPause();
                        }}
                        >
                        {isPlaying ? <FaPause color='#1877F2' size={'50px'} /> : <FaPlay color='#1877F2' size={'50px'}/>}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(Raw);
