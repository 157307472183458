import React, { useState, useEffect } from 'react';
import FormfacadeEmbed from "@formfacade/embed-react";

const Waitlist = () => {
    const [countdown, setCountdown] = useState(calculateCountdown());

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateCountdown() {
        const now = new Date();
        const targetDate = new Date(now.getFullYear(), now.getMonth() + 1, 1); // Set target date to next month
        const timeRemaining = targetDate - now;

        if (timeRemaining <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(timeRemaining / (1000 * 30 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    return (
        <section id="waitlist" className="py-5 main-content" style={{ background: 'linear-gradient(to right, #ff0000, #0095D9)'}}>
            <div className='container'>
                <div className="row justify-content-center" style={{marginTop: '5%' }}>
                    <div className="col-md-8 text-center mb-4">
                        <h1 className="mb-3" style={{ color: '#fff' }}><b>Join Our Waitlist</b></h1>
                        <b className="mb-4" style={{ color: '#fff' }}>Time remaining until waitlist closes:</b>
                        <h2 className="countdown mb-4 " style={{ color: '#fff' }}>
                            <b style={{color: '#fff', }}>{countdown.days}</b> days{",  "}
                            <b>{countdown.hours}</b> hours {",  "}
                            <b>{countdown.minutes}</b> minutes {",  "}
                            <b>{countdown.seconds}</b> seconds
                        </h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <FormfacadeEmbed
                            formFacadeURL="https://formfacade.com/include/102794897290577255947/form/1FAIpQLSeW3Xzm_xAGlLqN55SLgZjzODzcpvDZ2chxncYZkqlX_g_WKQ/classic.js/?div=ff-compose"
                            onSubmitForm={() => console.log('Form submitted')}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Waitlist;