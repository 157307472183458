import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import Waitlist from './components/WaitList';
import Main from './components';
import PrivacyPolicy from './view/PrivacyPolicy';
import TermOfUse from './view/TermOfUse';
import Contact from './view/Contact';
import Consent from './view/Consent';
import Permission from './view/Permission';
import RawCompo from './view/RawCompo';
import BookAPrivateDownload from './view/BookAPrivateDownload';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      {/* <div className="app-container">
        <Header /> */}
        <main className="main-content"
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Routes>
              {/* <Route path="/" element={<Main />} /> */}
              <Route path="/" element={<RawCompo />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/bookaprivate" element={<BookAPrivateDownload />} />
              <Route path="/waitlist" element={<Waitlist />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/term-of-use" element={<TermOfUse />} />
              <Route path="/consent/:token" element={<Consent />} />
              <Route path="/permission/:token" element={<Permission />} />
            </Routes>
          )}
        </main>
        {/* <Footer />
      </div> */}
    </BrowserRouter>
  );
}

export default App;
