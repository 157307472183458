import React from 'react';

const TermOfUse = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className="container mx-auto px-4">
                <div className="bg-black rounded-lg shadow-md p-8">
                    <h1 className="text-3xl font-semibold mb-4 w-full text-center">digiYO Terms of Use</h1>
                    <div >
                    <p style={{ fontSize: '14px', color: 'black' }}>
                    A. Information You Give Us Directly 

                        We collect information that you provide us, such as: 

                        Your account information, including your email address, username, real name, country, date of birth, telephone number and password; 

                        Payment and related information, such as your physical address, payment information, and transaction history 

                        Information about your profile, including your age, height, weight, location data, and social network information from Facebook, X, Instagram, and TikTok 

                        Security questions and answers; 

                        Information you provide in response to surveys about our services; 

                        Information you provide when seeking help from us, such as your name, telephone number, and records (including call recordings) of the issues you experience 

                        Information that you send through or save within our Services, such as communications with others on the platform, challenges issued, and posting your content. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        B. Information You Provide When Using our Services 

                        When you use our Services and social features, any text, audio, visual, and/or other electronic communications information that you disclose may be read, copied, collected, or used by other players, and may become publicly available. Please make sure your privacy settings reflect your preferences. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                    
                        C. Other Information We Collect When You Use Our Services 

                        When you use our Services, we collect information about which of those Services you’ve used and how you’ve used them. This helps us better understand the way our community uses our Services so that we can make improvements. This includes information such as 

                        Collection of data on how the Services are utilized by users 

                        Interaction details with Services, e.g., viewing and posting user-generated content, issuing or accepting challenges, communicating with other users within digiYO, and content details like posting time and viewers 

                        Hardware or software details, operating system, device memory, advertising identifiers, installed apps, information on browser type 

                        IP address based location data. 

                        Cookies, web beacons, web storage, and unique advertising identifiers. 

                        Details on how Services are used, access times, viewed pages, IP address, and identifiers like cookies. 

                        Device-level Permissions when granted, such as access to device phonebook, camera, photos, microphone, precise location information via GPS. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        D. Information Provided to Us by Third Parties 

                        We may receive information about you from third parties like other users, our affiliates, and third parties. This includes information like:  

                        Information obtained when you link your digiYO account with other services 

                        Information from advertisers, app developers, publishers to aid in ad targeting or measuring ad performance 

                        Utilizing contact lists uploaded by other digiYO users that include your information 

                        Receiving information from third parties (website publishers, social networks, law enforcement) regarding potential violations of Terms of Service and Community Guidelines. 

                        Social Platforms. When you use third-party social platforms (such as Facebook, X, Instagram, or TikTok) and link your digiYO Account, we may receive information associated with your account on those platforms. Please review the notices presented to you when linking your accounts for details on the information digiYO receives. 

                        Mobile Platforms. We receive information about you from the Google Play Store and Apple App Store. This information may include your username or device ID and the fact that you made a purchase, as applicable, but does not include any financial information. Some of our mobile platforms may send information to us that you authorize them to provide. We may also request additional personal information from you, such as push notification tokens or contact list, but we will collect this information only if you provide us with permission. 

                        Other Third Parties 

                        We may receive information from other third parties, like Facebook or Apple Game Center, when you use these services with our games. We may also receive information about you when other players choose to share their contact lists with us or from publicly-available websites. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        E. Legal Basis for Processing 

                        Our legal basis for processing your personal information varies depending on the type of information we collect, the context in which we collect it, as well as on your country of residence, as indicated when you are setting up your digiYO Account or, when not signed into an digiYO Account, your location as determined by your IP address and/or your device registration country. 

                        If you are a resident of the European Economic Area (EEA) or of a jurisdiction where similar legal requirements may apply such as Brazil, Switzerland, and the United Kingdom, we rely on a number of legal bases to process information about you. We will process information about you where we have your consent, where we have a legitimate interest to do so, where the processing is necessary for the performance of a contract with you, and/or where we have a legal obligation to process your information. For example, we typically rely on: 

                        consent when placing cookies on your device on the web (through our cookie consent manager), to send you email marketing, or to deliver third-party targeted advertising to you on our services; 

                        our legitimate interests to process your information when providing you with customer service support, to serve targeted digiYO marketing of our services, or for fraud prevention and security purposes; and 

                        on contractual necessity to process information when you create a digiYO Account or otherwise use our services to enable us to provide you with our services. 

                        If you are a resident in a territory where our legitimate interests mentioned above are not recognized as a lawful basis under applicable law, we will identify and use other appropriate lawful bases to process your personal information such as contractual necessity or your consent.
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        2. How We Collect Your Information 

                        We and third parties use technologies described below to collect information to deliver our services. 

                        A. Cookies and Similar Technologies 

                        Cookies are small text files stored on your Internet browser. We may also use these technologies to collect information when you interact with Services we offer through one of our partners. For example, we may use information collected on an advertiser’s website to show you more relevant ads. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies through the settings on your browser or device. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        3. How We Use Your Information 

                        We use your personal information, both individually and combined together, in the following ways. 

                        To operate our business and to enhance and personalize your game experience, including to: 

                        Provide personalized services and enhance product features; 

                        Ensure the operation, delivery, and maintenance of services, such as uploading posts, issuing or receiving challenges, communicating with athletes to book sessions, and compatibility with operating systems/devices; 

                        Tailor user experience by showing relevant content based on shared information and personalized content recommendations; 

                        Use interests/preferences to personalize, target, and measure ads for increased relevance; 

                        Conduct analytics to understand trends, usage, and demand for features; 

                        Engage in research to grasp consumer interests and service utilization patterns. 

                        

                        To provide you support, including to: 

                        Help identify and troubleshoot problems with your account or services; 

                        Facilitate surveys or questionnaires; 

                        Communicate with you and respond to your specific requests or questions; and 

                        Manage and send you confirmations and important information about your account, services, purchases, subscriptions, and warranties; 

                        Enhance service safety/security, identity verification, fraud prevention, and sending alerts for suspicious activities; 

                        Utilize data for enforcing terms, policies, legal compliance, and cooperation with law enforcement. 

                        To personalize our communications with you, including to: 

                        Provide you with relevant digiYO content, recommendations, information or offers; and 

                        Provide you with service updates. 

                        Retention. We retain the information we collect for as long as necessary to provide our services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons. 

                        Where possible, we may also de-identify, anonymize, or aggregate the information we collect, or collect it in a way that does not directly identify you. We may use and share such information as necessary for our business purposes and as permitted by applicable law. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        4. Who May Receive Your Information 

                        Service providers. Vendors and other service providers that help us operate our business and provide you with services may receive your personal information. We require that any service provider processing your personal information only do so on our behalf and for purposes consistent with this policy. 

                        Business partners. We may provide personal information to business partners with whom we jointly offer services. These partners include the companies that provide the platform on which you play our games, such as the App Store or Play Store. 

                        Affiliates and subsidiaries. We may disclose personal information to other companies in which we have an ownership interest as necessary for the purposes outlined in this policy. 

                        Advertising partners. Through our services, we may allow third-party advertising partners to use technologies and other tracking tools to collect information regarding your use of digiYO's services and your device (such as your IP address, mobile identifiers, pages visited, location, browser information, time of day). We also may share information relating to your device and your use of our services with our third-party advertising partners so they may deliver targeted advertisements to you when you visit third-party services and websites within their networks. This practice is commonly referred to as "interest-based advertising," "online behavioral advertising," or "targeted advertising." 

                        Disclosures to protect us or others. We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our or others' rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity.  

                        Disclosure in the event of merger, sale, or other asset transfer. In the event of a reorganization, divestiture, merger, sale, or bankruptcy, we may transfer all information we collect to the relevant third party and will obtain your consent to do so if required by law or contract. 

                        Third-party plugins. When third-party technologies or social tools are integrated into our services, those third parties may collect information about your use of our services. An example is the Facebook "Like" button. If you decide to connect your digiYO account with a third-party app, we will share any additional information you direct us to. 

                        With your consent. We may disclose your information to other recipients with your express consent, unless otherwise described above. For example, you may agree to us sharing your information with a certain company or organization to hear about their products, services, or promotions, or if you tell us that you want to link your digiYO Account with another company’s services. These recipients will process the information you agree to share according to their respective privacy notices. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        5. Where We Hold Your Information 

                        When you access digiYO services, including when available on third-party platforms, your personal information is generally collected by digiYO directly and processed in the United States. Personal information we collect may be subsequently stored and processed for the purposes set out in this Privacy and Cookie Policy in the United States or any other country in which digiYO operates. By consenting to the transfer of your personal data outside of your country of residence, you acknowledge that your personal information may be transferred to recipients in the United States and other countries that may not offer the same level of privacy protection as the laws in your country of residence or citizenship. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        6. How We Protect Your Information 

                        Security of your information is a priority at digiYO, and we take a range of technical and organizational measures to help protect it, including encryption of sensitive information. Even so, please be aware that no security measure is perfect. Thus, we cannot guarantee the security of your information at all times. You should always be diligent when it comes to the protection of your personal information. 

                        We allow users to report pieces of content that violate our policies. We also rely on the security measures and protocols used by our third-party payment processing, cloud hosting, and digital distribution service providers and partners. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        7. Children 

                        digiYO recognizes the importance of protecting the privacy of children who may access our services online. We strive to ensure that children who access our services receive age-appropriate experiences by employing data minimization, privacy by design, and default practices. We consider the best interests of children when determining the age-appropriate experience they receive and take into account the developmental maturity of children of different ages. 

                        When booking private coaching sessions, users under the age of 18 will only be able to book these sessions through a supervised parent/guardian account. Parents / Guardians will engage in all communication with the user offering coaching sessions. Only users over the age of 18 are allowed to create coach profiles. 

                        If we make material changes to how we collect, use, share or otherwise process your child's personal information, we will notify parents as required by applicable law. 

                        We encourage you to talk with your children about communicating with strangers and the risks of disclosing personal information online. You and your child should review the privacy settings applicable to their use of our services. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        8. Your Choices and Controls 

                        We give you meaningful choices when it comes to our collection and use of your information. To access, correct, or delete data from our servers, account administrators can use the digiYO app to update their profile or delete their accounts. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        9. Third-Party services 

                        Our websites and services may contain links to third-party services. If you click on those links, you will leave the digiYO Service and go to the third-party service or site that you selected. If you visit a third-party website or use a third-party service, you should consult that service's or site's privacy policy as your use of third-party sites or services is governed by their privacy policies. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        10. Information for California Residents 

                        Depending on your use of our services, the following categories of personal information may have been collected and disclosed in the preceding 12 months, as these categories are defined under the California Consumer Privacy Act ("CCPA"): 

                        Identifiers; such as a real name, user name, account name, email, online identifiers, device identifiers, IP address, or mobile device identifiers; 

                        Account login data; we collect and process the credentials necessary for you to access your digiYO Account; for any third-party connected accounts, following your authorization, we collect an access token that allows us to receive information from that third-party specific to your account with them; 

                        Purchase information; such as payment card number, payment account details, billing address, and other payment information; 

                        Characteristics of Protected Classifications under state or federal law; such as age or gender; 

                        Commercial Information; such as products or services interaction and purchase history; 

                        Internet or Electronic Network Activity information; such as, browsing history, search history, and information regarding interactions with our games, websites, applications, and/or advertisements; 

                        Approximate (coarse) geolocation data; such as location data derived from IP address or device settings; 

                        Audio or Electronic information; such as when you play our games, play certain multiplayer modes, use chat features, or participate in competitive game modes or live events; 

                        Contents of players' in-game messaging; 

                        Inferences; such as consumer preferences drawn from the above. 

                        In certain circumstances, we may also collect and disclose additional information, such as: 

                        If you are the recipient of a contest or sweepstakes prize that requires tax reporting, we may collect identifiers associated with your government-issued documents (such as a social security number). 

                        If you participate in our competitive gaming events, we may collect identifiers associated with your government-issued documents (such as a passport number), financial information (such as bank accounts), and health-related information. 

                        If you participate in our user experience research, we may collect financial information (such as bank accounts), health-related information and video footage of your gameplay, as well as your recorded image throughout the session. 

                        We may collect and disclose other categories of personal information to the extent that you provide them through in-game chat, forums, or through our player support channels. 

                        The following categories of personal information may be "sold" or "shared" as those terms are defined under the CCPA, for purposes of targeted advertising: 

                        Identifiers; such as a real name, user name, account name, email, online identifiers, device identifiers, IP address, or mobile device identifiers; 

                        Characteristics of Protected Classifications under state or federal law; such as age or gender; 

                        Commercial Information; such as products or services interaction and purchase history; 

                        Internet or Electronic Network Activity information; such as, browsing history, search history, and information regarding interactions with our games, websites, applications, and/or advertisements; 

                        Approximate (coarse) geolocation data; such as location data derived from IP address or device settings; 

                        Audio or Electronic information; such as when you play our games, play certain multiplayer modes, use chat features, or participate in competitive game modes or live events; 

                        Inferences; such as consumer preferences drawn from the above. 

                        We do not "sell" or "share," as those terms are defined under the CCPA, personal information of consumers. 

                        We retain the information we collect for as long as necessary to provide our services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        11. Changes to Our Policy 

                        We may occasionally update this privacy policy. When we do, we will revise the "last updated" date at the bottom of the policy. If there are material changes to this policy, we will use reasonable efforts to notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification. 
                    </p>
                    <p style={{ fontSize: '14px', color: 'black' }}>
                        12. Contact Us 

                        If you have questions about this policy, please contact us at help@digiyo.com. 

                        To contact digiYO, you can also use the following address: digiYO, Inc., 19 Morris Avenue, Bldg 128, Brooklyn, NY 11205 
                    </p>
                </div>
            </div>
        </div>
    </div>
    )
};

export default TermOfUse;