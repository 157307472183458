import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import underConstructionImg from "../assets/images/spinner.gif";
// import UnderConstruction from "../components/UnderConstruction";
import "../App.css";
import UnderConstruction from "../components/UnderConstruction";

const Permission = () => {
  const { token } = useParams(); // Extract the token from the URL
  const [modalMessage, setModalMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const showModal = (message, isSuccess) => {
    setModalMessage(message);
    setIsSuccess(isSuccess);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const sendRequest = () => {
    const url = `https://apitest.digiyo.com/users/submit-permission/${token}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.ok) {
            showModal("Thank you!", true);
          } else {
            showModal(
              // data.message ||
              "Permission access expired",
              false
            );
          }
        })
      )
      .catch((error) => {
        console.error("Fetch error:", error);
        showModal("Something went wrong", false);
      });
  };

  useEffect(() => {
    if (token) {
      sendRequest();
    } else {
      showModal("Missing URL parameter: token", false);
    }
  }, [token]);

  return (
    <div style={styles.content}>
      <div style={styles.frame}>
        <img
          src={require("../assets/images/spinner.gif")}
          alt="loading..."
          width={80}
        />
      </div>
      {modalVisible && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <p
              style={{
                ...styles.modalMessage,
                color: isSuccess ? "#4CAF50" : "#f44336",
              }}
            >
              {modalMessage}
            </p>
            <button style={styles.closeButton} onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "center",
  },
  modalOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "80%",
    textAlign: "center",
  },
  modalMessage: {
    fontSize: "1.2rem",
    marginBottom: "20px",
  },
  closeButton: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007BFF",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  frame: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Adjust this value as needed
    backgroundColor: "white", // Optional: Add a background color if needed
  },
};
export default Permission;
