import React, { memo } from 'react';
import contactImage from '../assets/images/underconstruction.jpg';

const ContactPage = () => {
  return (
    <section id="contact" className="contact">
      <div className="circle"></div>
      <div className="triangle"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <img className="img-fluid" src={contactImage} alt="Contact" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="title-box">
              <div className="title">Contact Us</div>
              <div className="">Get in touch with us for any inquiries or assistance</div>
            </div>
            <div className="contact-info">
              <div className="info">
                <i className="fas fa-envelope"></i>
                <span>Email: help@digiyo.com</span>
              </div>
              <div className="info">
                <i className="fas fa-phone"></i>
                <span>Phone: +13472240793</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(ContactPage);
