import React from 'react';
import load_gif from '../assets/images/logo2.png';

const Loader = () => {
  return (
    <div id="loading">
      <div id="loading-center">
        <img src={load_gif} alt="loader" />
      </div>
    </div>
  );
};

export default Loader;